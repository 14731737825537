<template>
    <div class="admin-personas overflow-hidden full-height">
        <template v-if="loading">
            <v-container fluid class="pa-6 full-height full-width">
                <div class="full-height full-width d-flex align-center justify-center">
                    <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                </div>
            </v-container>
        </template>

        <template v-else>
            <v-container fluid class="pa-6 full-height">
                <app-table
                    title="Users"
                    :items="personas"
                    :headers="headers"
                    :items-per-page="15"
                    :buttons="buttons"
                    @openPersonaDialog="openPersonaDialog"
                    @toggleJudge="toggleJudge"
                    @open="open"
                    @openDeleteDialog="openDeleteDialog"
                    @clicked="open"
                ></app-table>
            </v-container>
        </template>

        <persona-dialog ref="personaDialog" @confirm="confirm"></persona-dialog>
        <alert-dialog
            ref="deleteDialog"
            title="Delete"
            action-color="error"
            action-label="Delete"
            @confirm="confirmDelete"
        ></alert-dialog>
    </div>
</template>

<script>
// Services
import Persona from "@/modules/app-module/persona";
// Components
import AppTable from "@/components/table/app-table.vue";
import PersonaDialog from "@/components/dialogs/persona-dialog.vue";
import AlertDialog from "@/components/dialogs/alert-dialog.vue";

export default {
    name: "admin-users",

    meta: {
        title: "Users",
    },

    components: {
        AppTable,
        PersonaDialog,
        AlertDialog,
    },

    props: {},

    data() {
        return {
            cache: 0,
            tab: 0,
            loading: true,
            personas: [],
            buttons: [
                {
                    text: "Create User",
                    icon: "mdi-plus",
                    color: "primary",
                    function: "openPersonaDialog",
                },
            ],
            headers: [
                {
                    text: "Name",
                    value: "title",
                    type: "text-cell",
                    width: "1%",
                },
                {
                    text: "Username",
                    value: "username",
                    type: "text-cell",
                    // width: "1%",
                },
                // {
                //     text: "Artist",
                //     value: "artist",
                //     width: "1%",
                //     buttons: [
                //         {
                //             function: "toggleArtist",
                //             component: "switch",
                //         },
                //     ],
                //     type: "action-cell",
                //     sortable: false,
                //     align: "right",
                //     // color: "primary"
                // },
                {
                    text: "Judge",
                    value: "judge",
                    width: "1%",
                    buttons: [
                        {
                            function: "toggleJudge",
                            component: "switch",
                        },
                    ],
                    type: "action-cell",
                    sortable: false,
                    align: "right",
                    // color: "primary"
                },
                // {
                //     text: "Categories",
                //     value: "categories",
                //     type: "object-cell",
                //     populate: true,
                //     // align: "right",
                // },
                {
                    text: "Created",
                    value: "created",
                    type: "date-cell",
                    width: "1%",
                },
                {
                    text: "Action",
                    value: "action",
                    buttons: [
                        {
                            icon: "mdi-open-in-new",
                            function: "open",
                        },
                        {
                            icon: "mdi-delete-outline",
                            function: "openDeleteDialog",
                            color: "error",
                        },
                    ],
                    type: "action-cell",
                    width: "1%",
                    sortable: false,
                    align: "right",
                    // color: "primary"
                },
            ],
        };
    },

    watch: {},

    computed: {},

    methods: {
        async init() {
            try {
                const currentOrg = this.$store.getters["org/getOrg"];

                //Get All detail Sheets
                const personaPayload = {
                    status: "active",
                    _type: "contactdetail",
                    definition: "registeredOrg",
                    // "data.activeOrgs": { $in: [currentOrg] }, //TO DO, make this work to increase performance
                };

                const details = await this.$fluro.api.post("/content/_query", personaPayload).then(({ data }) => data);
                //Filter to one that contain only the current org
                const filtered = details.filter((p) => p?.data?.activeOrgs.some((org) => org._id == currentOrg));
                console.log("FILTERED", filtered);
                const requiredPersona = filtered.map((p) => {
                    if (p?.managedAuthor) {
                        return p.managedAuthor._id;
                    }
                });
                console.log("REQ", filtered);
                //Get all persona that match that _id
                const personas = await this.$fluro.content.getMultiple("persona", requiredPersona).then((res) => res);

                this.personas = personas.map((persona) => {
                    // let contact = contacts.find((contact) => contact?.data?.personaId == persona._id);
                    let judge = this.checkJudge(persona);
                    // let artist = this.checkArtist(persona);

                    if (judge) {
                        persona.judge = true;

                        // if (contact?._id) {
                        //     persona.categories = contact?.data?.categories || [];
                        // }
                    } else {
                        persona.judge = false;
                    }

                    // if (artist) persona.artist = true;
                    // else persona.artist = false;

                    return persona;
                });

                console.log("PERSONAS", this.personas);
            } catch (err) {
                console.log("ERROR", err);
            }
        },
        checkJudge(persona) {
            const JUDGE_ACCESS = this.$app.judgeRole;
            console.log(this.$app.judgeRole, this.$app.liveRealm);
            return persona.permissionSets.some(
                (permissionSet) =>
                    permissionSet.roles.some((permission) => permission._id == JUDGE_ACCESS) &&
                    permissionSet.realms.some((realm) => realm._id == this.$app.liveRealm)
            );
        },
        // checkArtist(persona) {
        //     const ARTIST_ACCESS = this.$app.artistRole;
        //     return persona.permissionSets.some(
        //         (permissionSet) =>
        //             permissionSet.roles.some((permission) => permission._id == ARTIST_ACCESS) &&
        //             permissionSet.realms.some((realm) => realm._id == this.$app.liveRealm)
        //     );
        // },
        async toggleJudge(persona) {
            const JUDGE_ACCESS = this.$app.judgeRole;
            console.log(this.checkJudge(persona), "Judge Access Status");

            let user = this.$app.user;

            try {
                persona.switching = true;

                // if judge, remove judge permission
                if (this.checkJudge(persona)) {
                    let permissionSet = persona.permissionSets.find((permissionSet) => {
                        return (
                            permissionSet.roles.some((permission) => permission._id == JUDGE_ACCESS) &&
                            permissionSet.realms.some((realm) => realm._id == this.$app.liveRealm)
                        );
                    });

                    let updated = persona.permissionSets.filter((permission) => permission != permissionSet);

                    let payload = {
                        permissionSets: updated,
                        realms: [this.$app.peopleRealm, this.$app.oldLiveRealm],
                    };

                    let updatedPersona = await Persona.update(persona._id, payload).then(({ data }) => data);

                    console.log(updatedPersona, "REMOVED Judge Access");
                }
                // if not, add judge permission
                else {
                    persona.permissionSets.push({
                        realms: [this.$app.liveRealm],
                        roles: [JUDGE_ACCESS],
                    });

                    let payload = {
                        permissionSets: persona.permissionSets,
                        realms: [this.$app.peopleRealm, this.$app.oldLiveRealm],
                    };

                    let updatedPersona = await Persona.update(persona._id, payload).then(({ data }) => data);

                    console.log(updatedPersona, "Judge Access ADDED");
                }

                persona.switching = false;

                if (user && user.persona && user.persona === persona._id) {
                    this.$fluro.api.get("/session/refresh", { cache: false });
                    await new Promise((res) => setTimeout(res, 100));
                    this.$fluro.api.get("/session/refresh", { cache: false });
                    await new Promise((res) => setTimeout(res, 100));
                    this.$fluro.api.get("/session/refresh", { cache: false });
                    await new Promise((res) => setTimeout(res, 100));
                    await this.$fluro.api.get("/session/refresh", { cache: false });

                    let session = await this.$fluro.auth.sync();

                    console.log("SESSION", session);

                    await this.$app.init(session);
                }

                await this.init();
            } catch (err) {
                console.log("ERROR", err);
            }
        },
        // async toggleArtist(persona) {
        //     const PHOTO_AWARDS_REALM = this.$app.liveRealm;
        //     const ARTIST_ACCESS = this.$app.artistRole;

        //     console.log(this.checkArtist(persona), "Artist Access Status");

        //     let user = this.$app.user;

        //     try {
        //         persona.switching = true;

        //         // if artist, remove artist permission
        //         if (this.checkArtist(persona)) {
        //             let permissionSet = persona.permissionSets.find((permissionSet) => {
        //                 return (
        //                     permissionSet.roles.some((permission) => permission._id == ARTIST_ACCESS) &&
        //                     permissionSet.realms.some((realm) => realm._id == this.$app.liveRealm)
        //                 );
        //             });

        //             let updated = persona.permissionSets.filter((permission) => permission != permissionSet);

        //             let payload = {
        //                 permissionSets: updated,
        //                 realms: [PHOTO_AWARDS_REALM],
        //             };

        //             let updatedPersona = await Persona.update(persona._id, payload).then(({ data }) => data);

        //             console.log(updatedPersona, "REMOVED Artist Access");
        //         }
        //         // if not, add judge permission
        //         else {
        //             persona.permissionSets.push({
        //                 realms: [PHOTO_AWARDS_REALM],
        //                 roles: [ARTIST_ACCESS],
        //             });

        //             let payload = {
        //                 permissionSets: persona.permissionSets,
        //                 realms: [PHOTO_AWARDS_REALM],
        //             };

        //             let updatedPersona = await Persona.update(persona._id, payload).then(({ data }) => data);

        //             console.log(updatedPersona, "Artist Access ADDED");
        //         }

        //         persona.switching = true;

        //         if (user && user.persona && user.persona === persona._id) {
        //             this.$fluro.api.get("/session/refresh", { cache: false });
        //             await new Promise((res) => setTimeout(res, 100));
        //             this.$fluro.api.get("/session/refresh", { cache: false });
        //             await new Promise((res) => setTimeout(res, 100));
        //             this.$fluro.api.get("/session/refresh", { cache: false });
        //             await new Promise((res) => setTimeout(res, 100));
        //             await this.$fluro.api.get("/session/refresh", { cache: false });

        //             let session = await this.$fluro.auth.sync();

        //             console.log("SESSION", session);

        //             await this.$app.init(session);
        //         }

        //         await this.init();
        //     } catch (err) {
        //         console.log("ERROR", err);
        //     }
        // },
        open(item) {
            this.$router.push({ name: "admin.user", params: { id: item._id } });
        },
        openPersonaDialog() {
            console.log(this.personas);
            this.$refs.personaDialog.open();
        },
        openDeleteDialog(item) {
            this.$refs.deleteDialog.open(item, `Are you sure you want to delete <b>${item.title}</b>?`);
        },
        async confirm(data) {
            await this.$fluro.resetCache();
            this.loading = true;
            await this.init();
            this.loading = false;
            this.$refs.personaDialog.close();
        },
        async confirmDelete(item) {
            try {
                let deleted = await Persona.delete(item._id).then(({ data }) => data);

                console.log("DELETE", deleted);
            } catch (err) {
                console.log("ERROR", err);
            }

            await this.$fluro.resetCache();
            this.loading = true;
            await this.init();
            this.loading = false;
            this.$refs.deleteDialog.close();
        },
    },

    async mounted() {
        this.loading = true;
        await this.init();
        this.loading = false;
    },
};
</script>

<style lang="scss"></style>
