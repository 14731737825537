<template>
    <v-dialog v-model="display" persistent content-class="confirm" :fullscreen="$vuetify.breakpoint.xsOnly" width="600">
        <v-card :key="cache" class="persona-dialog no-selection dark--text">
            <div class="dialog-error error px-4" :class="{ active: showError }">
                <div class="text d-flex align-center">
                    <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                    <b class="white--text small--text">{{ errorMessage }}</b>
                </div>

                <v-btn x-small icon fab color="white" @click="showError = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <div class="pa-3">
                <p class="text-center mb-0"><b v-text="title"></b></p>
            </div>

            <v-container fluid>
                <v-row dense>
                    <v-col cols="6">
                        <p class="primary--text small--text">First Name</p>
                        <v-text-field
                            ref="email"
                            v-model="model.firstName"
                            type="text"
                            class="field"
                            :disabled="loading"
                            dense
                            filled
                            solo
                            flat
                            placeholder="Please enter first name..."
                            required
                            :rules="[rules.required]"
                        >
                        </v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <p class="primary--text small--text">Last Name</p>
                        <v-text-field
                            ref="email"
                            v-model="model.lastName"
                            type="text"
                            class="field"
                            :disabled="loading"
                            dense
                            filled
                            solo
                            flat
                            placeholder="Please enter last name..."
                            required
                            :rules="[rules.required]"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12">
                        <p class="primary--text small--text">Email Address</p>
                        <v-text-field
                            ref="email"
                            v-model="model.username"
                            type="email"
                            class="field"
                            :disabled="loading"
                            dense
                            filled
                            solo
                            flat
                            placeholder="Please enter email..."
                            required
                            :rules="[rules.required, rules.email]"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row dense>
                    <!-- <v-col cols="12">
                        <v-switch class="ma-0" v-model="model.artist" inset>
                            <template v-slot:label>
                                <span class="small--text">Give access to the Artist portal</span>
                            </template>
                        </v-switch>
                    </v-col> -->

                    <v-col cols="12">
                        <v-switch class="ma-0" v-model="model.judge" inset>
                            <template v-slot:label>
                                <span class="small--text">Give access to the Judging portal</span>
                            </template>
                        </v-switch>
                    </v-col>
                </v-row>

                <!-- <v-row dense v-if="model.judge">
                    <v-col cols="12">
                        <autocomplete-field :key="display" :selected="categories" v-model="model.categories" hide-details return-object :loading="loading"></autocomplete-field>
                    </v-col>
                </v-row> -->
            </v-container>

            <v-card-actions class="actions light pa-3 sticky bottom">
                <v-btn :color="cancelColor" uppercase @click="cancel">
                    <span class="dark--text">{{ cancelLabel }}</span>
                </v-btn>
                <v-btn :disabled="!isValid" :color="actionColor" uppercase @click="submit">{{ actionLabel }}</v-btn>
            </v-card-actions>

            <v-overlay color="light" opacity="0.85" class="p-absolute" v-if="loading">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import AutocompleteField from "@/components/form-field/autocomplete-field.vue";

// Services
// import Persona from "@/modules/app-module/persona";

// Packages
import _ from "lodash";
import uuid from "uuid";

export default {
    name: "persona-dialog",

    props: {
        title: {
            type: String,
            default() {
                return "Create User";
            },
        },
        actionLabel: {
            type: String,
            default() {
                return "OK";
            },
        },
        cancelLabel: {
            type: String,
            default() {
                return "Cancel";
            },
        },
        actionColor: {
            type: String,
            default() {
                return "primary";
            },
        },
        cancelColor: {
            type: String,
            default() {
                return "grey";
            },
        },
        hideAction: Boolean,
        hideCancel: Boolean,
    },

    components: {
        AutocompleteField,
    },

    data() {
        return {
            display: false,
            loading: false,
            showError: false,
            message: "",
            errorMessage: "",
            item: null,
            model: {},
            cache: 0,
            categories: {
                minimum: 1,
                maximum: 0,
                options: [],
                title: "What categories will they judge?",
                placeholder: "Please select categories...",
                type: "category",
                filters: [
                    {
                        key: "status",
                        comparator: "in",
                        values: ["active"],
                    },
                ],
                select: ["title", "data"],
            },
            rules: {
                required: (value) => !!value || "This field is required",
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
        };
    },

    computed: {
        isValid() {
            let model = this.model;

            if (!model.firstName || !model.firstName.length) return false;

            if (!model.lastName || !model.lastName.length) return false;

            if (!model.username || !model.username.length) return false;

            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let emailTest = pattern.test(model.username);

            if (!emailTest) return false;

            // if (model.judge) {
            //     if (!model.categories || !model.categories.length) return false;
            // }

            return true;
        },
    },

    methods: {
        orgData() {
            console.log("STORE", this.$store.getters["org/getOrgData"]);
            return this.$store.getters["org/getOrgData"];
        },
        init(model) {
            this.showError = false;
            this.loading = false;

            if (!model) {
                this.model = {};
            } else {
                let clonedModel = _.cloneDeep(model);
                this.model = clonedModel;
            }
        },
        open(model, message) {
            this.init(model);
            this.message = message;
            this.display = true;
        },
        error(message) {
            this.errorMessage = message;
            this.showError = true;
            this.loading = false;
        },
        confirm() {
            this.loading = true;
            this.$emit("confirm", this.model);
        },
        cancel() {
            this.$emit("cancel");
            this.close();
        },
        close() {
            this.display = false;
            this.model = {};
            this.cache++;
        },
        generatePassword() {
            return `${uuid.v4()}`;
        },
        async submit() {
            let model = this.model;

            this.loading = true;

            try {
                let payload = {
                    userToken: this.$fluro.auth.getCurrentToken(),
                    firstName: model.firstName,
                    lastName: model.lastName,
                    username: model.username,
                    password: this.generatePassword(),
                    realms: [this.$app.oldLiveRealm, this.$app.peopleRealm],
                    permissionSets: [],
                };

                const LIVE_REALM = this.$app.liveRealm;
                const ARTIST_ACCESS = this.$app.artistRole;
                const JUDGE_ACCESS = this.$app.judgeRole;
                const ORG_REALM = this.$app.orgRealm;

                //Always an artist now
                payload.permissionSets.push({
                    realms: [ORG_REALM],
                    roles: [ARTIST_ACCESS],
                });

                if (model.judge) {
                    payload.permissionSets.push({
                        realms: [LIVE_REALM],
                        roles: [JUDGE_ACCESS],
                    });

                    // payload.categories = model.categories.map(({ _id }) => _id);
                }

                // let response = await Persona.create(payload).then(({ data }) => data);
                let response = await this.$fluro.api
                    .post("/reaction/spark/629439086e85667ce71d4820", payload)
                    .then(({ data }) => data);

                if (response.result) {
                    console.log("ERROR", response.result);
                    this.showError = true;
                    this.errorMessage = response.result;
                    this.loading = false;
                } else if (response && response._id) {
                    await this.$fluro.auth.sendResetPasswordRequest(
                        {
                            username: response.username,
                            redirect: "/auth/reset",
                            mail: {
                                from: `${this.orgData.title} <mailsystem@iypsoftware.com>`,
                                subject: `${this.orgData.title}`,
                                text:
                                    "<p>Welcome " +
                                    response.firstName +
                                    `,</p><p>You have been invited to use the ${this.orgData.title}. You will need to set a password by clicking the following link.</p>`,
                            },
                        },
                        {
                            application: true,
                        }
                    );

                    console.log("RESPONSE", response);

                    this.$emit("confirm", response);
                }
            } catch (err) {
                console.log("ERROR", err);
                this.showError = true;
                this.errorMessage = err;
                this.loading = false;
            }

            // this.loading = false;
        },
    },
};
</script>

<style lang="scss">
.confirm {
    background: transparent !important;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
}

.persona-dialog {
    .actions {
        display: flex;
        align-items: center;

        .v-btn {
            width: 100%;
            flex-shrink: 1;
        }
    }
}
</style>
